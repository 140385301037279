.projets {
  transform: translateX(-100%);
  transition: transform 0.5s ease-in-out;
  opacity: 0;
  margin-top: 300px;
  max-width: 1440px;
  margin: auto;
  h2 {
    margin-left: 112px;
    margin-top: 120px;
  }

  &.animated {
    opacity: 1;
    transform: translateX(0);
    transition: transform 0.5s ease-in-out;
  }

  .projets-text {
    margin-left: 112px;
    margin-top: 80px;
    opacity: 0.55;
    font-style: italic;
  }

  a {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    text-decoration: none;
    margin-top: 40px;
    margin-left: 112px;
    p {
      width: 100%;
      font-style: italic;
      text-decoration: none;
      color: #f1eff7;
      margin-top: 20px;
    }
    .logo-github {
      width: 2%;
    }
  }

  .project-card {
    display: flex;
    margin-top: 50px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    box-shadow: 0px 2px 6px #ffffff73;
    border: 2px solid #bcabdd;
    border-radius: 70px;
    width: 319px;
    height: 207px;
    transition: transform 0.3s ease;
    cursor: pointer;

    &:hover {
      transform: scale(1.1);
      border-color: #33ffcc;
    }

    .languages {
      display: flex;
      align-items: center;

      img {
        width: 50px;
        height: 50px;
        margin-right: 10px;
      }
    }
  }

  .projects-list {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    gap: 50px;
  }
}

@media (max-width: 767px) {
  .projets {
    h2 {
      margin-left: 32px;
    }
    .projets-text {
      margin: 0;
      margin-top: 40px;
      margin-left: 32px;
    }
  }
  .logo-github {
    width: 10% !important;
  }
  a {
    margin: 0 !important;
    margin-left: 32px !important;
    margin-top: 30px !important;
  }
}
