footer {
  padding-top: 20px;
  padding-bottom: 20px;
  border-top: 2px solid #3d1a53;
  box-shadow: 0px -1px 0px #ffffff73;
  font-size: 18px;
  font-weight: bold;
  width: 100%;

  .footer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    max-width: 1440px;
    margin: auto;
  }

  .icon {
    width: 3%;
    margin-left: 20px;
    transition: transform 0.3s ease;

    &:hover {
      transform: scale(1.1) rotate(360deg);
    }
  }

  p {
    font-style: italic;
    font-size: 15px;
    flex: 1;
    text-align: center;
    opacity: 0.38;
  }
}

@media (max-width: 767px) {
  .icon {
    width: 7% !important;
    margin: 0 !important;
    margin-left: 8px !important;
  }

  footer {
    p {
      margin-left: -40px;
    }
  }
}
