.skills {
  margin-left: 112px;
  opacity: 0;
  transform: translateX(-100%);
  transition: transform 0.5s ease-in-out;
  max-width: 1440px;
  margin: auto;
  h2 {
    margin-left: 112px;
    margin-top: 50px;
  }

  &.animated {
    opacity: 1;
    transform: translateX(0);
    transition: transform 0.5s ease-in-out;
  }

  .icons {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    margin-top: 90px;
    margin-right: 112px;

    img {
      width: 25%;
      &:hover {
        transition: transform 0.8s ease;
        transform: rotate(360deg);
      }
    }

    .icon-container {
      text-align: center;
      margin-bottom: 40px;
      transition: transform 0.3s ease;

      &:hover {
        text-shadow: 0px 0px 1px #33ffcc;
      }
    }
  }
}

@media (max-width: 767px) {
  .skills {
    margin-left: 0;
    margin-bottom: 20px;
    h2 {
      margin-left: 32px;
    }

    .icons {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      margin-right: 0;

      .icon-container {
        text-align: center;
        margin-bottom: 20px;
      }
    }
  }
}
