.button {
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  height: 50px;
  padding-left: 45px;
  padding-right: 45px;
  box-shadow: 0px 2px 6px #ffffff73;
  border: 2px solid #906ec7;
  border-radius: 70px;
  opacity: 1;
  color: white;
  background: none;
  font-size: 17px;
  font-weight: bold;
  transition: transform 0.3s ease;

  &:hover {
    border-color: #33ffcc;
    transform: scale(1.1);
  }
}
