body {
  margin: 0;
  min-height: 100vh;
  font-family: heebo;
  font-weight: 400;
  color-scheme: light dark;
  color: #f1eff7;
  background: linear-gradient(#0d001c, #180525, #300f2c);
  transition-duration: 0.8s;
  margin: auto;
}

.loader {
  position: absolute;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
}

h2 {
  font-size: 30px;
}

::-webkit-scrollbar {
  width: 1px;
}

::-webkit-scrollbar-track {
  background-color: #0d001c;
}

.arrow-button {
  position: fixed;
  bottom: 20px;
  right: 20px;
  background: transparent;
  border: none;
  cursor: pointer;
  transition: opacity 0.9s ease;

  img {
    width: 30px;
    transition: transform 0.5s ease;
  }

  &:hover img {
    transform: rotate(360deg);
  }

  &.bounce {
    animation: bounce 1s infinite;
  }
}

html {
  scroll-behavior: smooth;
}

.full-page {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-top: 100px;

  @media (max-width: 767px) {
    margin-top: 50px !important;
    height: auto;
  }
}

.arrow-button {
  @media (max-width: 767px) {
    visibility: hidden;
  }
}
