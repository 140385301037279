h1 {
  font-size: 32px;
  font-weight: bold;
}

.hidden {
  opacity: 0;
}

.about {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  max-width: 1440px;
  margin: auto;
  margin-top: 100px;
}

.about-transition {
  transform: translateY(20px);
  transition: opacity 0.5s ease-in-out, transform 0.5s ease-in-out;
}

.presentation {
  display: flex;
  flex-direction: column;
  width: 55%;

  p {
    width: 80%;
    margin-bottom: 50px;
  }

  button {
    width: 36%;
  }
}

.avatar {
  width: 20%;
  margin-bottom: 85px;
}

.arrow-box {
  display: flex;
  justify-content: center;
  margin-top: 70px;
  margin-bottom: 80px;
}

.arrow {
  width: 2%;
}

@keyframes bounce {
  0%,
  20%,
  50%,
  80%,
  100% {
    transform: translateY(0);
  }
  40% {
    transform: translateY(-10px);
  }
  60% {
    transform: translateY(-5px);
  }
}

.arrow.bounce {
  animation: bounce 1s infinite;
}

@media (max-width: 767px) {
  .about {
    flex-direction: column;
    align-items: center;
    padding: 20px;
    text-align: center;
    margin-top: 120px;

    h1 {
      font-size: 21px;
    }
  }

  .presentation {
    width: 90%;
    justify-content: center;
    align-items: center;
    margin: 0 auto;
    text-align: center;
    p {
      width: 100%;
      margin-bottom: 20px;
      font-size: 15px;
    }
    button {
      width: 60%;
      margin: 0 auto;
    }
  }

  .avatar {
    position: absolute;
    top: 8px;
    width: 20%;
  }

  .arrow-box {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .arrow {
    width: 5%;
  }
}
