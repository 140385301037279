.modale-container {
  background-color: #180525;
  border-radius: 5; /* No border radius to cover the entire screen */
  height: 100vh;
  width: 100vw;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  .modale-txt {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    justify-content: center;
    width: 90%;
    margin: auto 20px auto;
    flex-grow: 1;
  }
  .modale-title {
    font-size: 25px;
  }
  .bold-text {
    font-size: 18px;
  }
}

.ReactModal__Content {
  border-radius: 0;
  width: 100%;
  height: 100%;
  border: none; /* Remove border */
  box-shadow: none; /* Remove shadow */
  display: flex;
  flex-direction: column;
}

.fa-arrow-up-right-from-square {
  font-size: 23px;
  color: #bcabdd;
}

.fa-x {
  font-size: 12px;
  opacity: 0.7;
  margin: 20px;
}

.icon-position {
  display: flex;
  justify-content: center;
  width: 100%;
  margin-bottom: 20px;

  a {
    display: flex;
    align-items: center;
    margin-right: 40px;
  }

  a:last-child {
    margin-right: 0;
  }
}

@media (max-width: 767px) {
  .modale-container {
    margin-top: 30px;
  }

  .modale-txt {
    margin-bottom: 0 !important;
  }

  .icon-position {
    margin-top: 50px !important;
  }
}
